import OrderItem from '../components/OrderItem'
import React, { useEffect, useState } from 'react';
import FundraisersService from "../services/FundraisersService";
import OrdersService from "../services/OrdersService";
import { useParams, useNavigate } from "react-router-dom";
import DonationForm from '../components/DonationForm';

export default function Order() {

    // Cash check can only be entered by the student's device and that takes them here.
    const { fundraiserId } = useParams();
    const { orderId } = useParams();
    const { fdid } = useParams();
    const { cashcheck } = useParams();
    const [order, setOrder] = useState();
    const [studentFirstName, setStudentFirstName] = useState("");
    const [studentLastName, setStudentLastName] = useState("");
    const [fundraiserDeviceId, setFundraiserDeviceId] = useState();
    const navigate = useNavigate();
    const [isCustom, setIsCustom] = useState(false);
    const [customDonation, setCustomDonation] = useState();
    const isGive = useState(false);
    const [isDonate, setIsDonate] = useState(false);
    const [hasShipping, setHasShipping] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const updateOrderTotal = () => {
        updateTotal();
        setOrder({ ...order });
    }
    useEffect(x => {
        if (fdid) {
            FundraisersService.getFundraiserDetailsForDevice(fdid).then((x) => {
                if (cashcheck) {
                    // Filter out items with itemId 43
                    x.fundraiserOrderItems = x.fundraiserOrderItems.filter(item => item.itemId !== 43);

                    // For each remaining item, filter its options
                    x.fundraiserOrderItems.forEach(item => {
                        item.fundraiserOrderItemOptions = item.fundraiserOrderItemOptions.filter(option => option.itemOptionTypeId === 0);
                    });
                }
                setOrder(x);
                setFundraiserDeviceId(x.FundraiserDeviceId);
                setIsDonate(x.isDonate);
                setIsActive(x.isActive);
                console.log(x);
                setHasShipping(x.hasShipping);
                setStudentFirstName(x.studentFirstName);
                setStudentLastName(x.studentLastName);
            });
        }
        if (fundraiserId) {
            FundraisersService.getFundraiserDetails(fundraiserId).then(x => {
                setOrder(x);
                console.log(x.hasShipping);
                setHasShipping(x.hasShipping);
                setIsDonate(x.isDonate);
                setIsActive(x.isActive);
            });
        }
        if (orderId) {
            OrdersService.getFundraiserOrder(orderId).then((x) => {
                setOrder(x);
                setIsDonate(x.isDonate);
                setIsActive(x.isActive);
                setStudentFirstName(x.studentFirstName);
                setStudentLastName(x.studentLastName);
                setIsCustom(x.donation);
            });
        }
    }, []);
    function updateTotal() {
        let _total = 0;
        let grandTotal = 0;
        order.fundraiserOrderItems.map(x => {
            x.fundraiserOrderItemOptions.map(y => {
                _total += y.qty * y.price;
            })
        });
        grandTotal = _total + (parseFloat(order.donation));
        order.orderTotal = grandTotal;
    }
    function incCount(y) {
        y.qty = y.qty + 1;
        updateOrderTotal();
    }

    function decCount(y) {
        if (y.qty > 0) {
            y.qty = y.qty - 1;
            updateOrderTotal();
        }
    }
    const roundToHundredth = (value) => {
        let placeMaker = value * 100;
        if (placeMaker != "NaN")
            return (placeMaker / 100).toFixed(2);
        return 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!studentFirstName || studentFirstName.length == 0) {
            alert("Please enter a first name for the student.")
        }
        else if (!studentLastName || studentLastName.length == 0) {
            alert("Please enter a last name for the student.")
        }
        else if (!order.isDonate && (!order.orderTotal || order.orderTotal <= 0)) {
            alert("Please select an item.")
        }
        else if (!order.orderTotal || (order.isDonate &&  order.orderTotal < 35)) {
            alert("Donation must be at least $35.00.")
        }
        else {
            order.studentFirstName = studentFirstName;
            order.studentLastName = studentLastName;
            order.FundraiserDeviceId = fundraiserDeviceId;
            if (cashcheck) {
                OrdersService.CreateOrder(order).then(x => navigate("/checkoutcashcheck/" + x.data + "/" + cashcheck));
            }
            else {
                OrdersService.CreateOrder(order).then(x => navigate("/checkout/" + x.data));
            }
        }
    };
    return (
        <div>
            {order && isActive === false && (
                <>
                    <h1>{order && order.teamName}</h1>
                    <p>This fundraiser is no longer active.</p>
                    <p>Thank you for your support.</p>
                </>
            )}

            {order && isActive === true && (
                <>
            <h1>{order && order.teamName}</h1>
            <h2>Student Information</h2>
            <p>Please enter the name of the student you would like to support.</p>
            <form onSubmit={handleSubmit} className="needs-validation pb-3" noValidate>
                <div className="row g-3 pb-4">
                    <div className="col-sm-6">
                        <label htmlFor="firstName" className="form-label" setcustomvalidity="Missing first Name">Student First Name</label>
                        <input type="text" className="form-control" id="firstName" placeholder="Student First Name"
                            value={studentFirstName}
                            onChange={e => setStudentFirstName(e.target.value)}
                            required
                        />
                        <div className="invalid-feedback">
                            Valid first name is required.
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="lastName" className="form-label">Student Last Name</label>
                        <input type="text" className="form-control" id="lastName" placeholder="Student Last Name" value={studentLastName}
                            onChange={e => setStudentLastName(e.target.value)}
                            required />
                        <div className="invalid-feedback">
                            Valid last name is required.
                        </div>
                    </div>
                    <DonationForm
                        order={order} customDonation={customDonation} isCustom={isCustom} isGive={isGive} isDonate={isDonate}
                        updateTotal={() => { updateOrderTotal() }} setIsCustom={(x) => setIsCustom(x)} setCustomDonation={(x) => setCustomDonation(x)} />
                    {order && order.fundraiserOrderItems.map((x, index) => {
                        return (
                            <div key={x.itemId}>
                                <OrderItem item={x} fundraiserId={order.fundraiserId} itemId={x.itemId} incCount={(a) => incCount(a)} decCount={(a) => decCount(a)} />
                            </div>
                        )
                    })}
                </div>
                        <div className="d-flex">
                            <strong className="h3 col-6">Total: ${roundToHundredth(order ? order.orderTotal : 0) === "NaN" ? 0 : roundToHundredth(order ? order.orderTotal : 0)}</strong>
                            <button className="btn btn-primary btn-lg col-6" type="submit">Continue to checkout</button>
                        </div>
                        <div className="d-flex">
                            {hasShipping && <span className="col-6 text-muted" style={{ fontSize: '0.9em' }}>Please note: Shipping costs will be calculated and displayed on the checkout page. The final amount may vary based on your delivery location and the items in your order.</span>}
                        </div>
                    </form>
                </>
            )}
        </div>
    );
}
