import React, { Component, useEffect, useState } from 'react';
import Db from "../services/OrdersService";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { QRCode } from 'react-qrcode-logo';

export default function SelectState(props) {
    //const [purchaseId, setPurchaseId] = useState(11);
    //const [fundraisers, setFundraisers] = useState();
    //const [fundraiserDevice, setFundraiserDevice] = useState();
    const [myFundraiser, setMyFundraiser] = useState();
    //const [qrCode, setQrCode] = useState();
    const { fundraiserDeviceGuid } = useParams();
    const [studentFirstName, setStudentFirstName] = useState("");
    const [studentLastName, setStudentLastName] = useState("");
    const [showButton, setShowButton] = useState(false);

    const navigate = useNavigate();

    const getMessage = () => {
        let itemText = "";
        if (myFundraiser.fundraiserDevice.fundraiser && myFundraiser.fundraiserDevice.fundraiser.fundraiserItems && myFundraiser.fundraiserDevice.fundraiser.fundraiserItems.length > 0) {
            const items = myFundraiser.fundraiserDevice.fundraiser.fundraiserItems.map(item => item.item.messageTextName || "").filter(Boolean);
            if (items.length > 1) {
                itemText = "purchase " + items.slice(0, -1).join(", ") + " or " + items.slice(-1);
            } else {
                itemText = "purchase " + items[0];
            }
        } else {
            itemText = "donate";
        }

        // Convert C# DateTime to JavaScript Date object and format it
        const endDate = new Date(myFundraiser.fundraiserDevice.fundraiser.endDate);
        const formattedEndDate = endDate.toLocaleDateString("en-US");

        let retValue = "This is " + studentFirstName + " " + studentLastName + " and we are raising money for our " + myFundraiser.fundraiserDevice.fundraiser.team.name + " program at " + myFundraiser.fundraiserDevice.fundraiser.school.name + ".\n";
        retValue += "\nTo support our program, please click on the link below to " + itemText + ". Your generous donation will help me reach my goal of $500 raised. Our fundraiser ends on " + formattedEndDate + ".\n";
        retValue += "\nThank you very much for your support!\n";
        retValue += "- " + studentFirstName + " " + studentLastName;

        return retValue;
    };



    const previewTicket = (ticketGuid) => { window.open('/MyOffers/' + ticketGuid, '_blank') };
    useEffect(x => {
        Db.getMyFundraiser(fundraiserDeviceGuid).then(x => {
            setMyFundraiser(x);

            if (x.fundraiserDevice.studentFirstName) {
                setStudentFirstName(x.fundraiserDevice.studentFirstName);
            }
            if (x.fundraiserDevice.studentLastName) {
                setStudentLastName(x.fundraiserDevice.studentLastName);
            }

            // Compute showButton if none of the item options are digital and if the item's id doesnt match cookie dough or beef sticks
            const shouldShowButton = x.fundraiserDevice.fundraiser.fundraiserItems.some(item =>
                (item.itemId !== 43 && item.itemId !== 44) &&
                item.item.itemsOptions.some(option => option.itemOptionTypeId === 0)
            );
            setShowButton(shouldShowButton);
        });

    }, []);

    const share = (e) => {
        if (navigator.share) {
            console.log(getMessage());
            navigator.share({
                title: 'order',
                text: getMessage(),
                url: 'https://' + window.location.host + '/Order/' + fundraiserDeviceGuid,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        myFundraiser.fundraiserDevice.StudentFirstName = studentFirstName;
        myFundraiser.fundraiserDevice.StudentLastName = studentLastName;
        Db.NewOrderWithFDID(myFundraiser.fundraiserDevice).then(x => {
            if (x.data != null) {
                navigate("/order/" + x.data)
            }
        });

    };

    const validateStudentInfo = (e) => {
        e.preventDefault();
        myFundraiser.fundraiserDevice.StudentFirstName = studentFirstName;
        myFundraiser.fundraiserDevice.StudentLastName = studentLastName;
        Db.NewOrderWithFDID(myFundraiser.fundraiserDevice);

    };
    return (
        <div>
            <h2>{myFundraiser?.fundraiserDevice?.fundraiser?.name}</h2>

            <form onSubmit={handleSubmit} className="needs-validation" noValidate>
                {/*<form >*/}
                <div className="row g-3">

                    <div className="col-sm-6">
                        <label htmlFor="firstName" className="form-label">First name</label>
                        <input type="text" className="form-control" id="firstName" placeholder=""
                            value={studentFirstName}
                            onChange={e => (
                                setStudentFirstName(e.target.value)
                            )}
                            onBlur={validateStudentInfo}
                            required />
                        <div className="invalid-feedback">
                            Valid first name is required.
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="lastName" className="form-label">Last name</label>
                        <input type="text" className="form-control" id="lastName" placeholder="" value={studentLastName}
                            onChange={e => (
                                setStudentLastName(e.target.value)
                            )}
                            onBlur={validateStudentInfo}
                            required />
                        <div className="invalid-feedback">
                            Valid last name is required.
                        </div>
                    </div>
                </div>
                <div id="orderOptions" style={{ display: studentFirstName && studentLastName ? 'block' : 'none' }}>
                    <div className="d-flex" style={{ paddingTop: 24 }}>
                        <a href={"/FundraiserOrders/" + myFundraiser?.fundraiserDevice?.guid} className="btn btn-primary btn-lg btn-block" type="button">
                            Orders: {myFundraiser?.ordersQty} - Total: ${myFundraiser?.ordersTotal}
                        </a>
                    </div>
                    {showButton &&
                        <div className="d-flex" style={{ paddingTop: 24 }}>
                            <button className="btn btn-primary btn-lg btn-block" type="submit">
                                New Order (Cash / Check)?
                            </button>
                        </div>
                    }
                    {myFundraiser && myFundraiser.fundraiserDevice && myFundraiser.fundraiserDevice.fundraiser.ticket && myFundraiser.fundraiserDevice.fundraiser.ticket.guid ?
                        <div className="d-flex" style={{ paddingTop: 24 }}>
                            <button className="btn btn-primary btn-lg btn-block" type="button" onClick={() => previewTicket(myFundraiser.fundraiserDevice.fundraiser.ticket.guid)}>
                                Digital Ticket Preview
                            </button>
                        </div> : <></>}

                    <div className="d-flex" style={{ paddingTop: 24 }}>
                        {/*<strong className="h3 col-6">Total : ${total}</strong>*/}
                        <button className="btn btn-primary btn-lg btn-block" type="button" onClick={share}>
                            Share
                        </button>
                    </div>
                    <div className="d-flex justify-content-center" style={{ padding: 24 }} >
                        {/*<strong className="h3 col-6">Total : ${total}</strong>*/}
                        {/*<img src={qrCode} className="img-thumbnail" />*/}
                        {/*<QRCode value={"https://" + window.location.host + "/Order/" + fdid} />*/}
                        {/*<div style={{ maxWidth: 500 }}>*/}
                        <QRCode
                            value={"https://" + window.location.host + "/Order/" + fundraiserDeviceGuid}
                            eyeRadius={5}
                            qrStyle="dots"
                            size={window.innerWidth < 300 ? window.innerWidth - 40 : 300}
                        />
                        {/*</div>*/}
                    </div>

                </div>
            </form>
        </div>
    );

}
